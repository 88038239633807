import React, { createContext, useState } from "react"

export const ScrollContext = createContext()

export const ScrollProvider = ({ children }) => {
  const [scroll, setScroll] = useState()
  return (
    <ScrollContext.Provider value={{ scroll, setScroll }}>
      {children}
    </ScrollContext.Provider>
  )
}
