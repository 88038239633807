import React from "react"

import { LoadingProvider } from "./src/context/loadingContext"
import { ScrollProvider } from "./src/context/ScrollContext"

import "./src/assets/scss/main.scss"

export const wrapRootElement = ({ element }) => (
  <LoadingProvider>
    <ScrollProvider>{element}</ScrollProvider>
  </LoadingProvider>
)
